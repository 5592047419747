<template>
  <div class="page">
    <div class="bar">
      <div class="nav">
        <div class="logo"></div>
        <div class="btn_frame">
          <div class="nav_btn" @click="select_nav(0)">
            首页
            <div class="active" v-if="nav === 0"></div>
          </div>
          <div class="nav_btn" @click="select_nav(1)">
            产品介绍
            <div class="active" v-if="nav === 1"></div>
          </div>
          <div class="nav_btn" @click="select_nav(2)">
            新闻中心
            <div class="active" v-if="nav === 2"></div>
          </div>
          <div class="nav_btn" @click="select_nav(3)">
            关于企业
            <div class="active" v-if="nav === 3"></div>
          </div>
        </div>
        <div class="btn" :class="[isCn ? 'cn' : 'en']" @click="lang">
          <span>{{ isCn ? "中" : "英" }}</span>
        </div>
        <div class="content_us" @click="go_bottom()">咨询联系</div>
      </div>
    </div>
    <div class="header_area" id="index0" ref="index0">
      <div class="header_img">
        <div class="notes"></div>
      </div>
    </div>
    <div class="product_area" id="index1" ref="index1">
      <div class="left_area"></div>
      <div class="right_area"></div>
      <div class="product_frame">
        <div class="product_title">{{ select_obj.model }}</div>
        <div class="product_dec">
          <div
            class="product_dec_line"
            v-for="(features, index) in select_obj.features"
            :key="index"
          >
            <div class="dot"></div>
            <div class="product_dec_txt">{{ features }}</div>
          </div>
        </div>
        <div class="product_type_btn">按型号</div>
        <div class="product_detail" :style="{ 'background-image': 'url(' + select_obj.cover + ')' }"></div>
        <div class="product_type">{{ select_obj.name }}</div>
        <div class="product_content" v-html="select_obj.description"></div>
        <div class="download_btn" @click="check_submit()">
          页&nbsp;面&nbsp;下&nbsp;载&nbsp;&nbsp;Page Download >>
        </div>
        <div
          class="product_play_btn"
          v-if="select_obj.video"
          @click="play_btn()"
        ></div>
        <div class="product_list">
          <swiper
            :navigation="{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }"
            loop
            :slides-per-view="3"
            :space-between="0"
            ref="mySwiper"
          >
            <swiper-slide
              class="page_slide"
              v-for="(item, index) in list"
              :key="index"
            >
              <div
                :class="[selected === index ? 'page_img selected' : 'page_img']"
                :style="{ 'background-image': 'url(' + item.cover + ')' }"
                @click="onSelectChange(item.id)"
              />
            </swiper-slide>
          </swiper>
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
      </div>
    </div>
    <div class="news_area" id="index2" ref="index2">
      <div class="news_bg">
        <div class="news_frame">
          <div
            class="news_line"
            v-for="(news, index) in news_list"
            :key="index"
            @click="go_detail(news.id)"
          >
            <span class="news_title">{{ news.title }}</span>
            <span class="news_time">{{ news.publish_at }}</span>
          </div>
        </div>
        <div class="news_page">
          <Page
            @change-page="changePage"
            :pagesize="pageSize"
            :total="total"
            :page="page"
          />
        </div>
      </div>
    </div>
    <div class="about_us_area" id="index3" ref="index3">
      <div class="about_us_bg">
        <div class="about_us_t1">
          苏州凌光红外科技有限公司成立于2021年12月，公司始终坚持正向研发，专注于高端实验室检测仪器的国产化，解决高精设备迫在眉睫的“卡脖子”技术问题。
        </div>
        <div class="about_us_t2">
          应用场景有且不仅限于芯片失效分析、电子器件热分析、医学近红外成像、材料热导检测、贴合检测等。公司目前研发推出的产品主要用于芯片电性失效分析。
        </div>
        <div class="about_us_t3">
          <strong>·&nbsp;核心研发团队来自</strong
          >哥伦比亚大学、上海交通大学、复旦大学、浙江大学等一流高校。<br />
          <strong>·&nbsp;技术骨干来自于</strong
          >FACEbook，上海微电子装备，上海航天院等知名企业。
        </div>
      </div>
    </div>
    <div class="content_us_area" id="index4" ref="index4">
      <div class="content_us_left_area"></div>
      <div class="content_us_right_area"></div>
      <div class="content_us_frame">
        <div class="qrcode1">企业公众号</div>
        <div class="qrcode2">企业微信号</div>
        <div class="content_us_txt">
          电话：&nbsp;&nbsp;<a href="tel:021-58580978" style="cursor: pointer"
            >021-58580978</a
          ><br />
          邮箱：&nbsp;&nbsp;<a
            href="mailto:salesfa@luxet.cn"
            style="cursor: pointer"
            >salesfa@luxet.cn</a
          ><br />
          地址：&nbsp;&nbsp;上海市浦东新区碧波路690号张江微电子港6号101-12室
        </div>
        <div class="content_us_table">
          <select
            :class="[alert === 1 ? 'input_frame1 alert' : 'input_frame1']"
            v-model="product"
          >
            <option value="" disabled selected hidden>
              请选择你感兴趣的产品名称（必填）
            </option>
            <option v-for="(item, index) in list" :key="index" :value="item.id">
              {{ item.model }}
            </option>
          </select>
          <input
            :class="[alert === 2 ? 'input_frame2 alert' : 'input_frame2']"
            type="text"
            placeholder="请输入姓名（必填）"
            v-model="name"
          />
          <input
            :class="[alert === 3 ? 'input_frame2 alert' : 'input_frame2']"
            type="text"
            placeholder="请输入公司（必填）"
            v-model="company"
          />
          <input
            :class="[alert === 4 ? 'input_frame2 alert' : 'input_frame2']"
            type="tel"
            maxlength="11"
            placeholder="请输入电话（必填）"
            v-model="tel"
          />
          <input
            :class="[alert === 5 ? 'input_frame2 alert' : 'input_frame2']"
            type="email"
            placeholder="请输入邮箱（选填）"
            v-model="email"
          />
          <textarea
            class="input_frame3"
            placeholder="在线留言（选填）"
            v-model="remark"
          ></textarea>
          <div class="submit_notes">
            *通过提交，所提供的数据将根据隐私政策执行您的要求
          </div>
          <div class="submit_btn" @click="submit_form()">提交留言</div>
        </div>
        <div class="foot_line">
          <span style="float: left">LUXET©2024</span>
          Copyright ©️ 2024 苏州凌光红外科技有限公司
          <a target="_blank" href="https://beian.miit.gov.cn/"
            >苏ICP备2023003870号</a
          >
          <span class="go_top" @click="go_top()">Back To Top</span>
        </div>
      </div>
    </div>
    <div class="video1_cover" v-show="video_cover">
      <div class="close" @click="close_cover()" />
      <video-player
        class="video-player-box"
        ref="videoPlayer"
        :options="playerOptions"
        :playsinline="true"
        customEventName="customstatechangedeventname"
        @ended="onPlayerEnded($event)"
      />
    </div>
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player/src";
import { useRouter } from "vue-router";
import { GET_COMMENTS, GET_NEWS, GET_PRODUCTS } from "../http/api";
import Page from "../components/Page";
import { createToaster } from "@meforma/vue-toaster";
import { isMobile } from "@basitcodeenv/vue3-device-detect";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const toaster = createToaster({
  position: "top",
  duration: 2500,
});

export default {
  name: "Home",
  components: {
    Swiper,
    SwiperSlide,
    videoPlayer,
    Page,
  },
  data() {
    return {
      nav: 0,
      list: [],
      news_list: [],
      total: 10,
      pageSize: 5,
      page: 1,
      playerOptions: {
        muted: true,
        fluid: true,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: "https://static.campaignchowsangsang.com/others/lg.mp4",
          },
        ],
        poster:
          "https://static.campaignchowsangsang.com/others/video_cover.png",
      },
      video_cover: false,
      select_obj: {
        cover: "",
        description: "",
        features: "",
        file: "",
        model: "",
        name: "",
      },
      selected: 0,
      auto_scroll: false,
      product: "",
      name: "",
      company: "",
      tel: "",
      email: "",
      remark: "",
      alert: null,
      isCn: true,
    };
  },
  setup() {
    const router = useRouter();
    const jump_page = (page_name, value, query) => {
      router.push({
        name: page_name,
        params: {
          id: value,
        },
        query: {
          id: query,
        },
      });
    };
    return {
      jump_page,
    };
  },
  created: function () {
    let language = localStorage.getItem("language");
    if (!language) {
      localStorage.setItem("language", "CN");
      language = "CN";
    }
    this.isCn = language === "CN";
    GET_NEWS(`page_size=${this.pageSize}`).then((res) => {
      this.news_list = res.results;
      this.total = res.count;
    });
    GET_PRODUCTS(`page_size=100&language=${language}`).then((res) => {
      let list = [];
      for (let i = 0; i < res.results.length; i++) {
        let temp = res.results[i];
        temp.description = res.results[i].description.replace(
          /\r\n/g,
          "<br />"
        );
        let array = res.results[i].features.split(/\r\n/);
        temp.features = array.filter((item) => item.trim() !== "");
        list.push(temp);
      }
      this.list = list;
      this.select_obj = list[0];
    });
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    if (isMobile) {
      const meta = document.querySelector("meta[name='viewport']");
      if (meta) {
        meta.setAttribute("content", `width=1000, user-scalable=no`);
      }
    }
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  methods: {
    go_top() {
      VueScrollTo.scrollTo(`body`);
    },
    go_bottom() {
      let body = document.body,
        html = document.documentElement;
      let height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      this.auto_scroll = true;
      VueScrollTo.scrollTo(`body`, 500, {
        offset: height,
        onDone: this.onDone,
      });
    },
    select_nav(index) {
      if (index !== this.nav) {
        this.nav = index;
        this.auto_scroll = true;
        VueScrollTo.scrollTo(`#index${index}`, 500, {
          offset: -58,
          onDone: this.onDone,
        });
      }
    },
    onDone() {
      this.auto_scroll = false;
      this.handleScroll();
    },
    handleScroll() {
      if (!this.auto_scroll) {
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let clientHeight = document.documentElement.clientHeight;
        let scrollHeight = document.documentElement.scrollHeight;
        if (scrollTop + clientHeight >= scrollHeight) {
          this.nav = 4;
        } else {
          let index1 = this.$refs.index1.getBoundingClientRect().top;
          let index2 = this.$refs.index2.getBoundingClientRect().top;
          let index3 = this.$refs.index3.getBoundingClientRect().top;
          if (index1 > 58) {
            this.nav = 0;
          } else if (index2 <= 622 && index2 > 58) {
            this.nav = 1;
          } else if (index3 <= 623 && index3 > 58) {
            this.nav = 2;
          } else {
            this.nav = 3;
          }
        }
      }
    },
    lang() {
      // this.isCn = !this.isCn;
      // localStorage.setItem("language", this.isCn ? "CN" : "EN");
      // GET_PRODUCTS(`page_size=100&language=${this.isCn ? "CN" : "EN"}`).then(
      //   (res) => {
      //     let list = [];
      //     for (let i = 0; i < res.results.length; i++) {
      //       let temp = res.results[i];
      //       temp.description = res.results[i].description.replace(
      //         /\r\n/g,
      //         "<br />"
      //       );
      //       let array = res.results[i].features.split(/\r\n/);
      //       temp.features = array.filter((item) => item.trim() !== "");
      //       list.push(temp);
      //     }
      //     this.list = list;
      //     this.select_obj = list[0];
      //   }
      // );
    },
    play_btn() {
      let video_url = this.select_obj.video;
      console.log(video_url);
      this.playerOptions.sources = [
        {
          type: "video/mp4",
          src: video_url,
        },
      ];
      this.video_cover = true;
      setTimeout(() => {
        this.$refs.videoPlayer.player.play();
      }, 200);
    },
    close_cover() {
      this.video_cover = false;
      this.$refs.videoPlayer.player.pause();
    },
    // 视频播完回调
    onPlayerEnded(event) {
      console.log(event);
    },
    onSelectChange(e) {
      let index = this.list.findIndex((obj) => obj.id === e);
      this.select_obj = this.list[index];
      this.selected = index;
    },
    go_detail(id) {
      this.jump_page("Detail", id);
    },
    check_submit() {
      let select_obj = this.select_obj;
      let submitInfo = localStorage.getItem("submitInfo")
        ? JSON.parse(localStorage.getItem("submitInfo"))
        : [];
      if (submitInfo && submitInfo.includes(select_obj.id)) {
        this.downloadFile(select_obj.file, select_obj.model);
      } else {
        this.jump_page("Form");
      }
    },
    changePage(page) {
      this.page = page;
      GET_NEWS(`page=${this.page}&page_size=${this.pageSize}`).then((res) => {
        this.news_list = res.results;
        this.total = res.count;
      });
    },
    downloadFile(data, fileName) {
      // 下载pdf文件
      const a = document.createElement("a");
      // 这里是将url转成blob地址，
      fetch(data)
        .then((res) => res.blob())
        .then((blob) => {
          // 将链接地址字符内容转变成blob地址
          let url = URL.createObjectURL(blob);
          a.href = url;
          a.setAttribute("download", fileName + ".pdf");
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(url);
          document.body.removeChild(a);
        });
    },
    submit_form() {
      if (this.product === "") {
        this.alert = 1;
        return;
      }
      if (this.name.trim() === "") {
        this.alert = 2;
        return;
      }
      if (this.company.trim() === "") {
        this.alert = 3;
        return;
      }
      const phone = /^1[3|4|5|6|7|8|9]\d{9}$/;
      if (!phone.test(this.tel)) {
        this.alert = 4;
        return;
      }
      const email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (this.email !== "") {
        if (!email.test(this.email)) {
          this.alert = 5;
          return;
        }
      }
      GET_COMMENTS({
        product: this.product,
        name: this.name,
        company: this.company,
        phone: this.tel,
        email: this.email,
        comment: this.remark,
      }).then(() => {
        toaster.success(`提交成功！`);
        let index = this.list.findIndex((obj) => obj.id === this.product);
        let temp = this.list[index];
        this.downloadFile(temp.file, temp.model);
        let submitInfo = localStorage.getItem("submitInfo")
          ? JSON.parse(localStorage.getItem("submitInfo"))
          : [];
        if (!submitInfo.includes(this.product)) {
          submitInfo.push(this.product);
          localStorage.setItem("submitInfo", JSON.stringify(submitInfo));
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  width: 100%;
}
.bar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 58px;
  background: #e7eaef;
  z-index: 10;
}
.nav {
  position: relative;
  width: 1000px;
  height: 58px;
  margin: 0 auto;
  .btn {
    width: 20px;
    height: 20px;
    margin: 20px 20px 0 0;
    border-radius: 3px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px #323044 solid;
    float: right;
    span {
      display: block;
    }
    &.cn {
      font-size: 14px;
      color: #323044;
      line-height: 20px;
      text-align: center;
    }
    &.en {
      font-size: 14px;
      color: #323044;
      line-height: 20px;
      text-align: center;
    }
  }
}
.logo {
  position: absolute;
  top: 18px;
  left: 88px;
  width: 92px;
  height: 22px;
  background: url("../assets/home_logo.png") center center no-repeat;
  background-size: contain;
}
.btn_frame {
  position: absolute;
  top: 0;
  left: 400px;
  width: 400px;
  height: 58px;
}
.nav_btn {
  position: relative;
  width: 100px;
  height: 58px;
  font-size: 16px;
  line-height: 58px;
  color: #221815;
  text-align: center;
  float: left;
}
.active {
  position: absolute;
  top: 44px;
  left: 47px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: #b10011;
}
.content_us {
  width: 92px;
  height: 30px;
  margin: 15px 15px 0 0;
  border-radius: 6px;
  background: #b10011;
  font-size: 12px;
  color: #ffffff;
  line-height: 30px;
  letter-spacing: 2px;
  text-align: center;
  float: right;
}

.header_area {
  width: 100%;
  height: 567px;
  padding-top: 58px;
  background: url("../assets/home_bg1.png") bottom center repeat-x;
  background-size: auto 100%;
  .header_img {
    position: relative;
    width: 1000px;
    height: 567px;
    margin: 0 auto;
    background: url("../assets/home_bg.png") center center no-repeat;
    background-size: contain;
    .notes {
      position: absolute;
      bottom: 158px;
      right: 57px;
      width: 52px;
      height: 12px;
      background: url("../assets/home_slide.png") center center no-repeat;
      background-size: contain;
    }
  }
}
.product_area {
  width: 100%;
  height: 563px;
  .left_area {
    width: 50%;
    height: 563px;
    background: url("../assets/product_bg_left.png") bottom center repeat-x;
    background-size: auto 100%;
    float: left;
  }
  .right_area {
    width: 50%;
    height: 563px;
    background: url("../assets/product_bg_right.png") bottom center repeat-x;
    background-size: auto 100%;
    float: right;
  }
  .product_frame {
    position: relative;
    width: 1000px;
    height: 563px;
    margin: 0 auto;
    background: url("../assets/product_bg.png") center center no-repeat;
    background-size: contain;
    z-index: 2;
    .product_title {
      position: absolute;
      top: 104px;
      left: 96px;
      font-size: 26px;
      color: #3b4355;
      line-height: 26px;
    }
    .product_dec {
      position: absolute;
      top: 175px;
      left: 104px;
      width: 200px;
      .product_dec_line {
        width: 100%;
        height: 26px;
        font-size: 12px;
        color: #3b4355;
        line-height: 26px;
        .dot {
          width: 4px;
          height: 4px;
          margin: 11px 8px;
          border-radius: 3px;
          background: #3b4355;
          float: left;
        }
        .product_dec_txt {
          float: left;
        }
      }
    }
    .product_type_btn {
      position: absolute;
      top: 342px;
      left: 104px;
      width: 54px;
      height: 20px;
      border-radius: 10px;
      background: #b10011;
      font-size: 12px;
      color: #ffffff;
      line-height: 20px;
      text-align: center;
    }
    .product_detail {
      position: absolute;
      top: 70px;
      left: 405px;
      width: 190px;
      height: 346px;
      background: center center no-repeat;
      background-size: contain;
      z-index: 3;
    }
    .product_type {
      position: absolute;
      top: 164px;
      left: 652px;
      font-size: 18px;
      color: #3b4355;
      line-height: 20px;
    }
    .product_content {
      position: absolute;
      top: 205px;
      left: 652px;
      width: 215px;
      font-size: 10px;
      color: #3b4355;
      line-height: 24px;
      text-align: left;
    }
    .download_btn {
      position: absolute;
      top: 325px;
      left: 652px;
      width: 205px;
      height: 33px;
      border-radius: 6px;
      background: #b10011;
      font-size: 12px;
      color: #ffffff;
      line-height: 33px;
      text-align: center;
    }
    .product_play_btn {
      position: absolute;
      bottom: 104px;
      right: 92px;
      width: 61px;
      height: 61px;
      background: url("../assets/product_btn_play.png") center center no-repeat;
      background-size: contain;
      z-index: 2;
    }
    .product_list {
      position: absolute;
      top: 375px;
      left: 95px;
      width: 300px;
      height: 80px;
      .page_slide {
        width: 100%;
        height: 80px;
        .page_img {
          width: 80px;
          height: 80px;
          border-radius: 9px;
          margin: 0 auto;
          background: #ffffff center center no-repeat;
          background-size: contain;
          &.selected {
            border: 1px solid #b10011;
            border-radius: 9px;
          }
        }
      }
      .swiper-button-prev {
        left: -20px;
        color: transparent;
        background: url("../assets/product_btn_select_left.png") center center
          no-repeat;
        background-size: 12px auto;
        z-index: 5;
      }
      .swiper-button-next {
        right: -20px;
        color: transparent;
        background: url("../assets/product_btn_select_right.png") center center
          no-repeat;
        background-size: 12px auto;
        z-index: 5;
      }
    }
  }
}
.news_area {
  width: 100%;
  height: 564px;
  background: url("../assets/news_bg1.png") bottom center repeat-x;
  background-size: auto 100%;
  .news_bg {
    position: relative;
    width: 1000px;
    height: 564px;
    margin: 0 auto;
    background: url("../assets/news_bg.png") center center no-repeat;
    background-size: contain;
    .news_frame {
      position: absolute;
      top: 180px;
      left: 220px;
      width: 550px;
      z-index: 1;
      .news_line {
        width: 100%;
        font-size: 18px;
        color: #3b4355;
        line-height: 24px;
        margin-bottom: 24px;
        &:after {
          content: ".";
          display: block;
          height: 0;
          clear: both;
          visibility: hidden;
        }
        .news_title {
          width: 370px;
          text-align: left;
          float: left;
        }
        .news_time {
          width: 180px;
          text-align: right;
          float: left;
        }
      }
    }
    .news_page {
      position: absolute;
      bottom: 82px;
      right: 225px;
      width: 200px;
      z-index: 2;
    }
  }
}
.about_us_area {
  width: 100%;
  height: 556px;
  background: #ffffff;
  .about_us_bg {
    position: relative;
    width: 1000px;
    height: 564px;
    margin: 0 auto;
    background: url("../assets/about_bg.png") center center no-repeat;
    background-size: contain;
    .about_us_t1 {
      position: absolute;
      top: 233px;
      left: 116px;
      width: 425px;
      font-size: 12px;
      color: #727171;
      line-height: 20px;
      text-align: left;
    }
    .about_us_t2 {
      position: absolute;
      top: 374px;
      left: 116px;
      width: 425px;
      font-size: 12px;
      color: #727171;
      line-height: 20px;
      text-align: left;
    }
    .about_us_t3 {
      position: absolute;
      top: 435px;
      left: 116px;
      width: 480px;
      font-size: 12px;
      color: #727171;
      line-height: 20px;
      text-align: left;
    }
  }
}
.content_us_area {
  width: 100%;
  height: 570px;
  .content_us_left_area {
    width: 50%;
    height: 570px;
    background: url("../assets/contact_bg_left.png") bottom center repeat-x;
    background-size: auto 100%;
    float: left;
  }
  .content_us_right_area {
    width: 50%;
    height: 570px;
    background: url("../assets/contact_bg_right.png") bottom center repeat-x;
    background-size: auto 100%;
    float: right;
  }
  .content_us_frame {
    position: relative;
    width: 1000px;
    height: 570px;
    margin: 0 auto;
    background: url("../assets/contact_bg.png") center center no-repeat;
    background-size: contain;
    z-index: 2;
    .qrcode1 {
      position: absolute;
      top: 309px;
      left: 140px;
      width: 60px;
      height: 18px;
      padding-top: 60px;
      font-size: 12px;
      color: #ffffff;
      line-height: 18px;
      text-align: center;
      background: url("../assets/contact_QRcode1.png") top center no-repeat;
      background-size: 100% auto;
      z-index: 2;
    }
    .qrcode2 {
      position: absolute;
      top: 309px;
      left: 222px;
      width: 60px;
      height: 18px;
      padding-top: 60px;
      font-size: 12px;
      color: #ffffff;
      line-height: 18px;
      text-align: center;
      background: url("../assets/contact_QRcode2.png") top center no-repeat;
      background-size: 100% auto;
      z-index: 2;
    }
    .content_us_txt {
      position: absolute;
      top: 422px;
      left: 85px;
      font-size: 11px;
      color: #ffffff;
      line-height: 25px;
      text-align: left;
    }
    .content_us_table {
      position: absolute;
      top: 217px;
      left: 418px;
      width: 508px;
      height: 300px;
      text-align: left;
      .input_frame1 {
        width: 496px;
        height: 36px;
        font-size: 12px;
        color: #b1b3b7;
        line-height: 36px;
        padding-left: 12px;
        padding-right: 24px;
        border-radius: 6px;
        margin-bottom: 8px;
        background: url("../assets/contact_select.png") #20242f center right
          12px no-repeat;
        background-size: 6px auto;
        &.alert {
          border: 1px solid #b10011;
        }
      }
      .input_frame2 {
        width: 231px;
        height: 36px;
        font-size: 12px;
        color: #b1b3b7;
        line-height: 36px;
        padding-left: 12px;
        border-radius: 6px;
        margin-bottom: 8px;
        background: #20242f;
        display: block;
        &.alert {
          border: 1px solid #b10011;
        }
      }
      .input_frame2::-webkit-input-placeholder {
        /*WebKit browsers*/
        color: #b1b3b7;
      }
      .input_frame2::-moz-input-placeholder {
        /*Mozilla Firefox*/
        color: #b1b3b7;
      }
      .input_frame2::-ms-input-placeholder {
        /*Internet Explorer*/
        color: #b1b3b7;
      }
      .input_frame2::placeholder {
        color: #b1b3b7;
      }
      .input_frame3 {
        position: absolute;
        top: 44px;
        right: 12px;
        width: 228px;
        height: 168px;
        font-size: 12px;
        color: #b1b3b7;
        line-height: 36px;
        padding-left: 12px;
        border-radius: 6px;
        background: #20242f;
      }
      .input_frame3::-webkit-input-placeholder {
        /*WebKit browsers*/
        color: #b1b3b7;
      }
      .input_frame3::-moz-input-placeholder {
        /*Mozilla Firefox*/
        color: #b1b3b7;
      }
      .input_frame3::-ms-input-placeholder {
        /*Internet Explorer*/
        color: #b1b3b7;
      }
      .input_frame3::placeholder {
        color: #b1b3b7;
      }
      .submit_notes {
        position: absolute;
        bottom: 24px;
        left: 0;
        font-size: 11px;
        color: #b1b3b7;
        line-height: 30px;
      }
      .submit_btn {
        position: absolute;
        bottom: 20px;
        right: 0;
        width: 92px;
        height: 30px;
        border-radius: 6px;
        background: #b10011;
        font-size: 12px;
        color: #ffffff;
        line-height: 30px;
        letter-spacing: 2px;
        text-align: center;
      }
    }
    .foot_line {
      position: absolute;
      top: 527px;
      left: 80px;
      width: 840px;
      height: 32px;
      font-size: 12px;
      color: #b1b3b7;
      line-height: 32px;
      border-top: 2px rgba(255, 255, 255, 0.1) solid;
      text-align: center;
      .go_top {
        padding-right: 15px;
        background: url("../assets/contact_back.png") center right no-repeat;
        background-size: auto 10px;
        float: right;
      }
    }
  }
}
.video1_cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
  .close {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 66px;
    height: 66px;
    background: url("../assets/close.png") left center no-repeat;
    background-size: contain;
  }
  .video-player-box {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    width: 80%;
  }
}
</style>
